<template>
  <div class="md-layout sidebar-buttons md-gutter md-small-size-25 row">
    <div class="md-layout-item sidebar-buttons-container">
      <router-link :to="{ name: 'account' }" active-class="active" class="sidebar-button">
        <div class="user-dashboard-button">
          <p>
            {{ $t('sidebar.account') }}
            <i class="fas fa-user"></i>
          </p>
          <p class="mobile-fa">
            <i class="fas fa-user"></i>
          </p>
        </div>
      </router-link>
      <router-link :to="{ name: 'billing' }" active-class="active" class="sidebar-button">
        <div class="user-dashboard-button">
          <p>
            {{ $t('sidebar.billing') }}
            <i class="fas fa-receipt"></i>
          </p>
          <p class="mobile-fa">
            <i class="fas fa-receipt"></i>
          </p>

        </div>
      </router-link>
      <router-link :to="{ name: 'export' }" active-class="active" class="sidebar-button">
        <div class="user-dashboard-button">
          <p>
            {{ $t('sidebar.export') }}
            <i class="fas fa-file-export"></i>
          </p>
          <p class="mobile-fa">
            <i class="fas fa-file-export"></i>
          </p>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Sidebar',
  components: {},
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/layout/user-dashboard.scss';
</style>
